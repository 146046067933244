<template>
  <div style="background: #fff;color: #000;height: 100%;">
    <van-nav-bar title="旗下门店" v-if="login == 'true'" :fixed="true" :z-index="999" />
    <van-nav-bar
      v-else
      :title="title?title:'旗下门店'"
      left-arrow
      :fixed="true"
      :z-index="999"
      @click-left="back"
    />
    <div style="margin-top:48px;height: 100%;background: #fff;">
      <div class="m_list" v-for="item in list" :key="item.id" @click="tomendian(item)">
        <div class="left hea">
          <div class="m_img_div">
            <img class="m_img" :src="item.doorPic" alt />
            <img
              class="moren_img"
              v-if="item.defaultGId == 1"
              src="../..//assets/img/moren.png"
              alt
            />
          </div>
        </div>
        <div class="left heb">
          <div style="max-height: 50px">
            <span class="biaoshi">
              <span class="gcd" v-if="item.franchisee == 0">工场店</span>
              <span class="gcd" v-if="item.franchisee == 1">加盟店</span>
            </span>
            <!-- <img class="gcd" src="../../assets/img/gcd.png" alt /> -->
            <span style="vertical-align: middle;font-weight: bold;line-height: 18px;">{{item.name}}</span>
          </div>
          <div
            style="font-size: 12px;height: 24px;color: #666;white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;"
          >{{item.address}}</div>
          <div style="font-size: 12px;">
            <span class="left">
              <van-rate :size="12" allow-half color="#ee0a24" v-model="item.grade" readonly />
              <span style="color: #d81e06;">{{item.grade?item.grade.toFixed(1):''}}分</span>
            </span>
            <span class="right">
              <img class="cod" style="width: 14px;" src="../../assets/img/ding2.png" alt />
              <span style="vertical-align: middle;color: #666;margin-right: 12px;">{{item.juli}}km</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";

export default {
  data() {
    return {
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      isok: this.$route.query.isok,
      login: this.$route.query.login,
      title: this.$route.query.title,
      id: this.$route.query.id,
      comboCustomerId: this.$route.query.comboCustomerId,
      ogid: this.$route.query.ogid,
      showisok: this.$route.query.showisok,
      jd: JSON.parse(localStorage.getItem("JD")),
      wd: JSON.parse(localStorage.getItem("WD")),
      list: []
    };
  },
  methods: {
    tomendian(item) {
      if(item.id == 485){
        this.$toast('该门店无法变更！');
        return
      }
      if (this.isok) {
        this.$router.push({
          path: "shop",
          query: {
            gid: item.id
          }
        });
      } else {
        if (this.showisok == "meal") {
          let data = {
            gid: item.id,
            comboCustomerId: this.comboCustomerId
          };
          userModel
            .changegarage(data)
            .then(e => {
              this.$toast("已变更为 " + item.name);
              setTimeout(res => {
                this.$router.go(-1);
              }, 800);
            })
            .catch(() => loading.clear());
        } else if (this.showisok == "youhui_list") {
          let data = {
            ogid: this.ogid,
            gid: item.id
          };
          userModel
            .couponchangegarage(data)
            .then(e => {
              this.$toast("已变更为 " + item.name);
              setTimeout(res => {
                this.$router.go(-1);
              }, 800);
            })
            .catch(() => loading.clear());
        }  else if (this.showisok == "tuoke_coupon_details") {
          let data = {
            id: this.id,
            gid: item.id,
            garageName:item.name
          };
          userModel
            .balancegid(data)
            .then(e => {
              this.$toast("已指定为 " + item.name);
              var couponitem = JSON.parse(sessionStorage.getItem("couponitem"))
              couponitem.balanceGid = item.id
              couponitem.balanceGarageName = item.name
               sessionStorage.setItem("couponitem", JSON.stringify(couponitem));
              setTimeout(res => { 
                this.$router.go(-1);
              }, 800);
            })
            .catch(() => loading.clear());
        }else {
          let data = {
            gid: item.id,
            uid: this.userInfo.id
          };
          userModel
            .changedefault(data)
            .then(e => {
              sessionStorage.setItem("appid", item.idd);
              sessionStorage.setItem("gid", item.id);
              sessionStorage.setItem("shangjia", JSON.stringify(item));
              this.$toast("切换门店成功");
              setTimeout(res => {
                this.$router.go(-1);
              }, 600);
            })
            .catch(() => loading.clear());
        }
      }
    },

    namelist() {
      var data = {
        gid: this.shangjia.id
        // gid: 223
      };
      userModel
        .liansuolist(data)
        .then(e => {
          // loading.clear();
          this.list = e.data;
          if (this.list.length > 0) {
            this.list.forEach((item, index) => {
              if (JSON.parse(localStorage.getItem("JD")) != null) {
                if (item.positionWd != null) {
                  var radLat1 = (this.wd * Math.PI) / 180.0;
                  var radLat2 = (item.positionWd * Math.PI) / 180.0;
                  var a = radLat1 - radLat2;
                  var b =
                    (this.jd * Math.PI) / 180.0 -
                    (item.positionJd * Math.PI) / 180.0;
                  var s =
                    2 *
                    Math.asin(
                      Math.sqrt(
                        Math.pow(Math.sin(a / 2), 2) +
                          Math.cos(radLat1) *
                            Math.cos(radLat2) *
                            Math.pow(Math.sin(b / 2), 2)
                      )
                    );
                  s = s * 6378.137; // EARTH_RADIUS;
                  s = Math.round(s * 10000) / 10000;
                  var juli = s.toFixed(2);
                  item.juli = juli;
                } else {
                  item.juli = 999.0;
                }
              }
            });
          }
          if (this.list.length > 1) {
            this.list.sort((old, New) => {
              console.log(old);
              console.log(New);
              return old.juli - New.juli;
            });
          }
          console.log(this.list);
        })
        .catch(() => loading.clear());
    },
    getConfig() {
      let that = this;
      userModel
        .config({
          url: location.href.split("#")[0],
          gid: sessionStorage.getItem("gid")
        })
        .then(res => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: ["getLocation", "onMenuShareAppMessage"]
            });
            // wx.ready(() => {
            //   wx.onMenuShareAppMessage({
            //     title: "驿车驹门店",
            //     desc: "邀请您使用驿车驹在线服务！",
            //     link:
            //       "https://shopping.car-posthouse.cn/user?gid=" +
            //       sessionStorage.getItem("Agid"),
            //     imgUrl:
            //       "https://shopping.car-posthouse.cn/assets/img/fuwu1.2584c349.png",
            //     success: function() {}
            //   });
            // });
            wx.ready(() => {
              wx.getLocation({
                type: "wgs84", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                success: function(res) {
                  console.log(res);
                  var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                  var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                  localStorage.setItem("JD", res.longitude);
                  localStorage.setItem("WD", res.latitude);
                  var speed = res.speed; // 速度，以米/每秒计
                  var accuracy = res.accuracy; // 位置精度
                }
              });
            });
          } else {
            this.$toast(res.data.msg);
          }
        });
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    if (this.$route.query.denglu != undefined) {
      var url =
        this.$route.path +
        "?gid=" +
        this.$route.query.gid +
        "&isok=" +
        true +
        "&login=" +
        true;
      localStorage.setItem("beforeUrl", url);
      sessionStorage.setItem("Agid", this.$route.query.gid);
      sessionStorage.setItem("gid", this.$route.query.gid);
      userModel
        .getGarageInfo({ gid: this.$route.query.gid })
        .then(e => {
          // loading.clear();
          this.shangjia = e.data;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
          // 正式
          const redirect_uri = encodeURIComponent(
            "https://shopping.car-posthouse.cn/auths"
          );
          window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        })
        .catch(() => loading.clear());
    }
    // localStorage.setItem("JD", "113.891656");
    // localStorage.setItem("WD", "22.78564");
    this.getConfig();
    this.namelist();
  }
};
</script>
<style lang="less" scoped>
.m_list {
  overflow: hidden;
  padding: 12px 10px;
  border-bottom: 1px solid #f5f5f5;
  font-size: 14px;
}

.m_img {
  width: 70px;
  height: 70px;
}
.moren_img {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
}
.m_img_div {
  width: 70px;
  height: 70px;
  position: relative;
}
.hea {
  width: 23%;
}
.biaoshi {
  display: inline-block;
  background: #ef4136;
  font-size: 12px;
  color: #fff;
  line-height: 17px;
  padding: 0 0.5px;
  border-radius: 2px;
  margin-top: 1px;
  vertical-align: middle;
  margin-right: 5px;
}
.heb {
  width: 77%;
}
.gc {
  display: inline-block;
  color: #fff;
  background: red;
  font-size: 12px;
}
.gcd {
  display: inline-block;
  transform: scale(0.8);
}
.cod {
  vertical-align: middle;
}
</style>